
























































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import SvgAtSign from '@/components/svg/AtSign.vue';
import SvgCheck from '@/components/svg/Check.vue';
import SvgX from '@/components/svg/X.vue';

interface VuexBindings {
  isAvailable: (vanity: string) => boolean,
  isChecked: (vanity: string) => boolean,
  findVanity: (vanity: string) => ApiResource.Vanity|undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    SvgAtSign,
    SvgCheck,
    SvgX,
  },
  props: {
    business: {
      type: Object as () => ApiResource.Business,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      form: this.business.vanity,
      loading: false,
      validation: null as null|string,
    };
  },
  computed: {
    ...mapGetters('vanity', {
      isAvailable: 'IsAvailable',
      isChecked: 'IsChecked',
      findVanity: 'Find',
    }),
    baseUrl(): string {
      return process.env.VUE_APP_BASE_URL || '';
    },
    available(): boolean {
      return this.isAvailable(this.form);
    },
    checked(): boolean {
      return this.isChecked(this.form);
    },
  },
  methods: {
    async save(): Promise<void> {
      this.error = null;
      this.loading = true;

      await this.$store.dispatch('business/ChangeVanity', {
        BUSINESS_ID: this.business.id,
        form: {
          vanity: this.form,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
    async check(): Promise<void> {
      this.error = null;
      this.validation = null;

      this.validate();

      if (this.validation) return;

      this.loading = true;

      await this.$store.dispatch('vanity/Check', this.form)
        .catch((e) => { this.error = e; });

      this.loading = false;
    },
    validate(): void {
      if (!this.form) {
        this.validation = 'notOptional';
      } else if (this.form.length > 35) {
        this.validation = 'length';
      } else if (!new RegExp(/[a-z0-9]/gi).test(this.form)) {
        this.validation = 'alnum';
      }
    },
  },
});
