


























































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import VanityUrlForm from '@/components/dashboard/VanityUrlForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    VanityUrlForm,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    profileUrl(): string {
      return `${process.env.VUE_APP_BASE_URL}/maker/${this.business.vanity}`;
    },
    profilePermalink(): string {
      return `${process.env.VUE_APP_BASE_URL}/maker?id=${this.business.id}`;
    },
    profileShortUrl(): string {
      return `https://auftr.ag/${this.business.vanity}`;
    },
    aliasUrl(): (alias: string) => string {
      return (alias: string) => `${process.env.VUE_APP_BASE_URL}/maker/${alias}`;
    },
    aliasShortUrl(): (alias: string) => string {
      return (alias: string) => `https://auftr.ag/${alias}`;
    },
  },
});
