var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"id":"vanity"},on:{"submit":function($event){$event.preventDefault();_vm.available ? _vm.save() : _vm.check()}}},[_c('FormField',{attrs:{"field-key":"profile.vanity","field-validation":_vm.validation,"form-id":"vanity","has-implicit-label":"","has-addons":""}},[_c('p',{staticClass:"control"},[_c('BaseButton',{staticClass:"is-medium is-static",attrs:{"tabindex":"-1"}},[(!_vm.checked)?_c('SvgAtSign',{staticClass:"has-text-primary",attrs:{"width":"24","height":"24"}}):(_vm.available)?_c('SvgCheck',{staticClass:"has-text-link",attrs:{"width":"24","height":"24"}}):_c('SvgX',{staticClass:"has-text-danger",attrs:{"width":"24","height":"24"}})],1)],1),_c('FormInput',{attrs:{"field-key":"profile.vanity","form-id":"vanity","has-error":!!_vm.validation,"maxlength":"35","is-expanded":"","input-class":['is-medium']},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),(_vm.available || _vm.form === _vm.business.vanity)?_c('p',{key:"save",staticClass:"control"},[(_vm.available)?_c('BaseLinkSupportRequest',{staticClass:"button is-medium is-link",attrs:{"support-summary":_vm.$t('support_request', {
          vanity: _vm.form,
          current: _vm.business.vanity,
        })},domProps:{"textContent":_vm._s(_vm.$t('save'))}}):_c('BaseButton',{staticClass:"is-medium is-link",attrs:{"type":"submit","form":"vanity","is-loading":_vm.loading,"disabled":_vm.form === _vm.business.vanity},domProps:{"textContent":_vm._s(_vm.$t('save'))}})],1):_c('p',{key:"check",staticClass:"control"},[_c('BaseButton',{staticClass:"is-medium is-primary",attrs:{"type":"button","is-loading":_vm.loading,"disabled":_vm.form === _vm.business.vanity},domProps:{"textContent":_vm._s(_vm.$t('check-availability'))},on:{"click":_vm.check}})],1)],1),_c('transition',{attrs:{"mode":"out-in","enter-active-class":"animated fadeIn is-snappy-duration","leave-active-class":"animated fadeOut is-snappy-duration"}},[(_vm.available)?_c('p',{key:"available",staticClass:"help is-primary",domProps:{"textContent":_vm._s(_vm.$t('available', {
        baseUrl: _vm.baseUrl,
        vanity: _vm.form,
      }))}}):(_vm.findVanity(_vm.form))?_c('p',{key:"unavailable",staticClass:"help is-danger",domProps:{"textContent":_vm._s(_vm.$t(("validation." + (_vm.findVanity(_vm.form).reason))))}}):_c('p',{key:"none",staticClass:"help",domProps:{"textContent":_vm._s(_vm.$t('none', {
        baseUrl: _vm.baseUrl,
        vanity: _vm.form || 'vanity',
      }))}})]),_c('br'),_c('ErrorMessage',{attrs:{"error":_vm.error}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }